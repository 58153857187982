import { ApiRequest } from "../shared/APIRequest";
import { AppDomainHandler } from "../shared/app-domain-handler";

const allergensKey = "allergens";
const foodPreferencesKey = "food-preferences";
export class MasterDataDoamin extends AppDomainHandler {
  #api;
  static #instance;

  constructor() {
    super();

    if (MasterDataDoamin.#instance) return MasterDataDoamin.#instance; // singleton
    MasterDataDoamin.#instance = this;

    this.#api = ApiRequest.factory();
  }

  /**
   * Gets the singleton instance of the AccountDomain class.
   */
  static get singleton() {
    if (!this.#instance) new this();
    return this.#instance;
  }

  async getAllergens() {
    let data = await app.indexedDb.getData(allergensKey);
    if (data) return data;
    return await this.fetchAllergens();
  }

  allergens = [];
  async fetchAllergens() {
    let response;
    let data;

    try {
      const previousEtag = await app.indexedDb.getData(`${allergensKey}-etag`);
      const options = {
        rawResponse: true,
        headers: { ...(previousEtag && { "if-none-match": previousEtag }) },
      };

      response = await this.#api.getData(`/allergens`, options);
      const etag = response.headers.get("x-etag");
      data = await response.json();
      app.indexedDb.setData(allergensKey, data.data);
      app.indexedDb.setData(`${allergensKey}-etag`, etag);
    } catch (err) {
      switch (err.response && err.response.status) {
        case 304:
          data = await app.indexedDb.getData(allergensKey);
          break;
        default:
          break;
      }
    }

    if (!data) return false;
    this.allergens = data;
    return data;
  }

  async getFoodPreferences() {
    const data = app.indexedDb.getData(this.#foodPreferencesKey);
    if (data) return data;
    return await this.fetchFoodPreferences();
  }

  foodPreferences = [];
  #foodPreferencesKey = "food-preferences";
  async fetchFoodPreferences() {
    let response;
    let data;

    try {
      const previousEtag = await app.indexedDb.getData(
        `${foodPreferencesKey}-etag`
      );
      const options = {
        rawResponse: true,
        headers: { ...(previousEtag && { "if-none-match": previousEtag }) },
      };

      response = await this.#api.getData(`/food_preferences`, options);
      const etag = response.headers.get("x-etag");
      data = await response.json();
      app.indexedDb.setData(foodPreferencesKey, data.data);
      app.indexedDb.setData(`${foodPreferencesKey}-etag`, etag);
    } catch (err) {
      switch (err.response && err.response.status) {
        case 304:
          data = await app.indexedDb.getData(foodPreferencesKey);
          break;
        default:
          break;
      }
    }

    if (!data) return false;
    this.foodPreferences = data;
    return data;
  }
}
