import { html } from "lit";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../shared/pwa-page";
import { repeat } from "lit/directives/repeat.js";

const programs = [
  { name: "Acetylcholin", id: "ddddd" },
  { name: "Dopamin" },
  { name: "Ease" },
  { name: "GABA" },
  { name: "Omni" },
  { name: "Power" },
  { name: "Serotinin" },
  { name: "Shaping" },
];

export class PagePrograms extends OnboardedMixin(AuthenticatedMixin(PWAPage)) {
  static get properties() {
    return {
      programId: {
        type: String,
        routeOrigin: "pathname",
      },
    };
  }

  render() {
    return html`
      <section class="card wide">
        <h2>Your program</h2>
        <p>${this.renderActiveProgram()}</p>
      </section>

      <section class="card">
        <h2>Programs</h2>
        <p>Overview of all programs.</p>

        <nav class="tiles">
          ${repeat(programs, (item) => {
            return html`<a href="/programs/${item.id}">
              <section class="card">
                <h3>${item.name}</h3>
                <p></p>
              </section>
            </a>`;
          })}
        </nav>
      </section>
    `;
  }

  renderActiveProgram() {
    if (app.session.user.active_program)
      return html`<a href="/program">Go to program</a>`;

    return html`<p>No active program.</p>
      <p>Please finish your BrainCheck first</p>

      <p>
        <a href="/braincheck" class="button green outline">
          Go to the BrainCheck
        </a>

        
      </p> `;
  }
}
