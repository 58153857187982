import { msg } from "@lit/localize";
import { showAlert } from "@qogni-technologies/design-system/src/components/base/modal-dialog.js";
import {
  isMobile
} from "@qogni-technologies/design-system/src/shared/common";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { LitElement, html, nothing } from "lit";
import { createRef, ref } from "lit/directives/ref.js";
import { repeat } from "lit/directives/repeat.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { Converter } from "showdown";
import { TimelineDomain } from "../../domain/timeline-domain";
import "./reactions-list-dialog";

export class CommentListItem extends LitElement {
  createRenderRoot() {
    return this;
  }

  #commentRichEditorRef = createRef();
  #svgLShapedLineContainerRef = createRef();
  #profilePicRef = createRef();
  #domain;
  #nextCursor = null;

  static get properties() {
    return {
      postId: { type: String },
      post: { type: Object },
      comment: { type: Object },
      isReply: { type: Boolean, attribute: "is-reply" },

      _subComments: { type: Array },
      _pagination: { type: Object },
      _showCommentEditor: { type: Boolean },
      _commentEditDialogOpened: { type: Boolean },
      _reactedUserListDialogOpened: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.#domain = new TimelineDomain();
  }

  async profilePictureEl() {
    await this.updateComplete;
    return this.#profilePicRef.value;
  }

  #editComment() {
    this._commentEditDialogOpened = true;
  }

  async #getComment() {
    const task = async () => {
      const response = await this.#domain.getComment(
        this.postId,
        this.comment.id
      );
      this.comment = response?.data;
    };

    await Task.run(task, { ghost: this });
  }

  async #onCommentEditSuccess() {
    await this.#getComment();
  }

  async #likeComment(e) {
    const typeCode = e.detail?.name;
    const type = e.detail?.code;
    const stats = this.comment?.stats ?? {};

    const task = async () => {
      const oldLike = this.comment?.like;

      if (oldLike) {
        this.comment.stats[oldLike.type_code]--;
        this.comment.like = null;
      }
      if (!oldLike || oldLike.type !== type) {
        const userId = this.comment?.user_id;
        stats[typeCode]++;
        const like = {
          user_id: userId,
          type: type,
          type_code: typeCode,
        };
        this.comment.like = like;
        this.comment.stats = stats;
      }
      this.requestUpdate();
      await this.#domain.likeComment(this.postId, this.comment.id, type);
    };

    return Task.run(task, {
      ghost: this,
      description: "Like comment",
    });
  }

  async #addComment() {
    const task = async () => {
      const commentContent = this.#commentRichEditorRef.value.text;

      if (!commentContent) {
        return showAlert({
          title: msg("Please enter text for your comment!", {
            desc: "Prompt asking the user to input text for their comment.",
          }),
        });
      }

      const postId = this.post?.id;
      const commentId = this.comment?.id;
      await this.#domain.addComment(postId, commentContent, commentId);
      
      // close comment write section
      this._showCommentEditor = false;
      await this.#refreshComments();
    };

    await Task.run(task, {
      ghost: this,
      description: "Adding comment to post...",
    });
  }

  async #loadComments() {
    const task = async () => {
      const query = {
        parent_id: this.comment.id,
        ...(this.#nextCursor && { cursor: this.#nextCursor }),
      };
      const response = await this.#domain.getComments(this.postId, { query });

      if (this.#nextCursor) {
        this._subComments = [...(this._subComments ?? []), ...(response?.data ?? [])]
      } else {
        this._subComments = response?.data;
      }
      this._pagination = response.pagination;
      this.#nextCursor = response.pagination.next_cursor;
    };

    await Task.run(task, { ghost: this });
  }

  async #refreshComments() {
    await this.#getComment();
    await this.#loadComments();
  }

  async updated(changeProps) {
    
    if (changeProps.has("comment") || changeProps.has("_subComments")) {
      if (Number(this.comment?.stats?.comments ?? 0) && !isMobile()) {
        await this.updateComplete;
        
        const element1 = this.#profilePicRef.value;
        const element2 = await this.renderRoot.querySelector('comment-list-item[is-reply]:last-of-type').profilePictureEl();
        const container = this.#svgLShapedLineContainerRef.value;

        this.drawLShapeLine(element1, element2, container);
      }
    }
  }

  render() {
    const userId = app?.session?.user?.id;
    const commentUserId = this.comment?.user_id;
    const isMe = userId === commentUserId;

    const isAuthor = commentUserId === this.post?.user_id;

    return html`
      <div class="wrapper">
        <comment-author>
          <profile-picture
            name="${this.comment.user?.firstname} ${this.comment.user
              ?.lastname}"
            img="${this.comment.user?.profile_img_url}"
            uuid=${this.comment.user_id}
            link=${`/profile/${this.comment.user_id}`}
            size="20px"
            ${ref(this.#profilePicRef)}
          >
          </profile-picture>
          <span>
            <strong
              >${this.comment.user?.firstname}
              ${this.comment.user?.lastname}</strong
            >
            ${msg("commented")}
            <span
              class="time-ago"
              title="${Intl.DateTimeFormat(navigator.languages, {
                dateStyle: "short",
                timeStyle: "short",
              }).format(new Date(this.comment.created_at))}"
            >
              <time
                datetime="${new Date(this.comment.created_at).toString()}"
                data-update-interval="30000"
              ></time>
            </span>
            ${isAuthor
              ? html`<badge-tag>${msg("Author")}</badge-tag>`
              : nothing}
          </span>
          ${isMe
            ? html`
                <nav
                  id="account"
                  data-dropdown
                  class="comment align-right img-button"
                >
                  <button title="Menu" class="simple">
                    <svg-icon icon="meatballs"></svg-icon>
                  </button>
                  <menu>
                    <li>
                      <a @click=${() => this.#editComment(this.comment)}>
                        <svg-icon icon="pencil" size="20px"></svg-icon>
                        ${msg("Edit")}
                      </a>
                    </li>
                  </menu>
                </nav>
              `
            : nothing}
        </comment-author>

        <comment-content>
          ${unsafeHTML(new Converter().makeHtml(this.comment.content))}
        </comment-content>
        ${this.#renderSocialInteraction()} ${this.#renderSubComments()}
        ${this.#loadMoreComments()}
      </div>

      ${this.#renderFragments()} ${this.#renderAddComments()}
      ${this.#renderSvgLine()}
    `;
  }

  #renderSocialInteraction() {
    const reactionValue = () => {
      if (this.comment.like?.type === 0) return "like";
      if (this.comment.like?.type === 1) return "love";
      if (this.comment.like?.type === 2) return "idea";
      return "";
    };

    const like = this.comment.stats?.like ?? 0;
    const love = this.comment.stats?.love ?? 0;
    const idea = this.comment.stats?.idea ?? 0;

    const totalReactions = like + love + idea;

    const numOfSubComments = Number(this.comment?.stats?.comments);

    const likeSample = this.comment?.like_sample ?? [];
    const computedLikeSample = likeSample.map((e) => {
      const firstname = e.user?.firstname;
      const lastname = e.user?.lastname;
      const img = e.user?.profile_img_url;
      const uuid = e.user?.id;
      const name = firstname + " " + lastname;
      return { name, uuid, img };
    });

    return html`
      <comment-social-interaction>
        <post-reactions
          is-text
          value=${reactionValue()}
          @change=${this.#likeComment}
        >
        </post-reactions>
        ${totalReactions
          ? html`<span class="separator">•</span>
              <user-stack .users=${computedLikeSample} size="20px" @click=${() => (this._reactedUserListDialogOpened = true)}></user-stack>
              <span class="total" @click=${() => (this._reactedUserListDialogOpened = true)}>${totalReactions}</span>`
          : nothing}
        ${!this.isReply
          ? html`
              <span class="separator">|</span>
              <button
                class="reply simple"
                @click=${() =>
                  (this._showCommentEditor = !this._showCommentEditor)}
              >
                ${msg("Reply")}
              </button>
              ${numOfSubComments
                ? html`
                    <span class="separator">•</span>
                    <span
                      >${numOfSubComments}
                      ${numOfSubComments === 1 ? msg("Reply") : msg("Replies")}
                    </span>
                  `
                : nothing}
            `
          : nothing}
      </comment-social-interaction>
    `;
  }

  #renderAddComments() {
    if (!this._showCommentEditor) return nothing;

    return html`
      <div class="comment-rich-editor">
        <post-rich-editor
          name="content"
          placeholder=${msg("Add a comment...", {
            desc: "Placeholder text prompting the user to write a comment.",
          })}
          value=""
          rows="2"
          ${ref(this.#commentRichEditorRef)}
        >
        </post-rich-editor>
        <button class="small round" @click=${this.#addComment}>
          <svg-icon icon="send"></svg-icon>
        </button>
      </div>
    `;
  }

  #renderSubComments() {
    if (!this.comment?.comment_sample) return nothing;

    if (Array.isArray(this._subComments) && this._subComments.length > 0) {
      return html`
        ${repeat(this._subComments, (sc) => {
          return html`
            <comment-list-item
              is-reply
              .comment=${sc}
              .postId=${this.postId}
              .post=${this.post}
            ></comment-list-item>
          `;
        })}
      `;
    }

    return html`
      <comment-list-item
        is-reply
        .comment=${this.comment?.comment_sample}
        .postId=${this.postId}
        .post=${this.post}
      ></comment-list-item>
    `;
  }

  #renderFragments() {
    if (this._commentEditDialogOpened) {
      return html`
        <edit-comment-dialog
          open
          .postId=${this.postId}
          .comment=${this.comment}
          @close=${() => (this._commentEditDialogOpened = false)}
          @success=${this.#onCommentEditSuccess}
        ></edit-comment-dialog>
      `;
    }

    if (this._reactedUserListDialogOpened) {
      return html`
        <reactions-list-dialog
          .postId=${this.postId}
          .commentId=${this.comment?.id}
          .stats=${this.comment?.stats}
          @close=${() => (this._reactedUserListDialogOpened = false)}
        ></reactions-list-dialog>
      `;
    }

    return nothing;
  }

  #loadMoreComments() {
    const stats = this.comment?.stats;
    const numOfComments = Number(stats?.comments ?? 0);

    if (!numOfComments || numOfComments < 2)
      return nothing;
    if (this._pagination && !this._pagination.next_cursor) return nothing;
    return html`
      <button class="reply load-more simple" @click=${this.#loadComments}>
        <svg-icon icon="plus"></svg-icon>
        ${msg("Load more comments")}
      </button>
    `;
  }

  #renderSvgLine() {
    if (!Number(this.comment?.stats?.comments ?? 0) || isMobile()) return nothing;

    return html`
      <div class="svg-container" ${ref(this.#svgLShapedLineContainerRef)}></div>
    `
  }

  drawLShapeLine (element1, element2, container, cornerRadius = 5) {
    // Get the positions and dimensions of the elements
    const rect1 = element1.getBoundingClientRect();
    const rect2 = element2.getBoundingClientRect();
  
    // Calculate starting and ending points
    const startX = rect1.width / 2; // Right edge of element1
    const startY = rect1.height + 8; // Middle of element1
    const endX = rect2.left - rect1.left - 8; // Left edge of element2
    const endY = rect2.top - rect1.top + rect2.height / 2; // Middle of element2
  
    // Calculate the intermediate corner for the "L" shape
    const cornerX = startX;
    const cornerY = endY;
  
    // Ensure the radius doesn't exceed the distances
    const adjustedRadius = Math.min(cornerRadius, Math.abs(startY - cornerY), Math.abs(endX - cornerX));
  
    // Create an SVG element
    const svgNS = "http://www.w3.org/2000/svg";
    const svg = document.createElementNS(svgNS, "svg");
    svg.style.position = "absolute";
    svg.style.top = "0";
    svg.style.left = "0";
    svg.style.width = "100%";
    svg.style.height = "100%";
    svg.style.pointerEvents = "none"; // Make SVG non-interactive
  
    // Create a path for the line
    const path = document.createElementNS(svgNS, "path");
    path.setAttribute(
      "d",
      `
        M ${startX},${startY}                     
        L ${cornerX},${cornerY - adjustedRadius} 
        A ${adjustedRadius},${adjustedRadius} 0 0 0 ${cornerX + adjustedRadius},${cornerY}
        L ${endX},${endY}                       
      `
    );
  
    path.setAttribute("stroke", "var(--color-pastel-primary-000)");
    path.setAttribute("stroke-width", "2");
    path.setAttribute("fill", "none");
  
    // Add the path to the SVG
    svg.appendChild(path);
  
    container.innerHTML = '';
    // Add the SVG to the container
    container.appendChild(svg);
  }
}

customElements.define("comment-list-item", CommentListItem);
