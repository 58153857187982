import { AppDomainHandler } from "../shared/app-domain-handler";
import { ApiRequest } from "../shared/APIRequest";

export class RecommendationDomain extends AppDomainHandler {
  static #instance;
  #api;

  /**
   * Gets the singleton instance of the RecommendationDomain class.
   */
  static get singleton() {
    if (!this.#instance) new this();
    return this.#instance;
  }

  constructor() {
    super();

    if (RecommendationDomain.#instance) return RecommendationDomain.#instance; // singleton
    RecommendationDomain.#instance = this;

    this.#api = ApiRequest.factory();
  }

  /**
   * Fetches a list of recommended channels for the current user.
   *
   * This method interacts with the API endpoint to retrieve personalized
   * channel recommendations based on the user's profile and preferences.
   *
   * @return {Promise<Object>} A promise that resolves to the data from the API containing recommended channels.
   */
  async getRecommendedChannels() {
    return await this.#api.getData('/users/me/recommendations/channels');
  }
}
