import { html } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { AuthenticatedMixin, WidgetEnabledPWAPage } from "../shared/pwa-page";
import { msg } from "@lit/localize";

const data = {
  new: [
    {
      title: "Better support for allergies and diets",
      description: "We provide better support for allergies and diets in our recipes. More improvements coming soon.",
    },
    {
      title: "AI-buddy improvements",
      description: "We made the AI-buddy even better by making sure it can answer more specific and longer messages, we will keep working on improving our AI-buddy.",
    },
    {
      title: "Translations improved",
      description: "We keep working on making our App more accessible to people from all over the world. We are currently working on improving our translations, the first steps can already been seen in the new version of the App.",
    },
  ],
  cooking: [
    {
      title: "Health Hacks",
      description:
        "Personalized goals for improving your health with our Health Hacks.",
    },
    {
      title: "Timeline Improvements",
      description:
        "We will make your timeline even more personal by showing content that matters for you",
    },
  ],
};

export class PageWhatsNew extends AuthenticatedMixin(WidgetEnabledPWAPage) {
  renderPage() {
    return this.renderItems();
  }

  getWidgetSettings() {
    return {
      ...(super.getWidgetSettings() ?? {}),
      priority: 900,
      title: msg('New and Upcoming', {desc: 'Label for widget that shows new and upcoming features.'}),
      cta: {
        href: "/whats-new",
      },
    };
  }

  renderWidget() {
    return html`
      <section class="card">
        <h3>New Qogni Features</h3>
        <ul class="mb-small">
          ${repeat(data.new, (item) => {
            return html`<li>${item.title}</li>`;
          })}
        </ul>
      </section>
    `;
  }

  get title(){
    return "What's New"
  }

  renderItems(compact) {
    return html`

      <section class="card">
        <h3>${msg('New Qogni Features')}</h3>
        <ul class="mb-small">
          ${repeat(data.new, (item) => this.renderItem(item, compact))}
        </ul>
      </section>

      <section class="card">
        <h3>
          ${compact
            ? html`${msg('Coming in the next releases')}`
            : html`${msg("Check out what's coming in the next releases")}`}
        </h3>
        <ul>
          ${repeat(data.cooking, (item) => this.renderItem(item, compact))}
        </ul>
      </section>
    `;
  }

  renderItem(item, compact) {
    return html`<li>
      ${item.title} ${compact ? "" : html`<small>${item.description}</small>`}
    </li>`;
  }
}
