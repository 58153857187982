import { html } from "lit";
import { LightElement } from "../common";
import "@whereby.com/browser-sdk/embed";
import { msg } from "@lit/localize";
import { ApiRequest } from "../APIRequest";

customElements.define(
  "video-meeting",
  class VideoMeeting extends LightElement {
    #api;

    constructor() {
      super();
      this.#api = ApiRequest.factory();
    }

    static get properties() {
      return {
        conversationId: { type: String },
        roomId: { type: String },
        roomLink: { type: String },
        open: { type: Boolean },
      };
    }

    render() {
      this.open = !!this.roomId;

      return html`<section class="video-panel ${this.open ? "open" : ""}">
        ${this.renderVideoPanelContent()}
      </section>`;
    }

    renderVideoPanelContent() {
      const roomUrl = this.getRoomUrl(this.roomId);
      if (roomUrl)
        return html`
          <whereby-embed 
            lang="${app.session.user.language.substring(0, 2)}"
            background="off"
            floatSelf
            localization="off"
            video="off"
            logo="off"
            room="${roomUrl}"
          ></whereby-embed>
        `;
      else if (this.open) {
        return html`
          <nav class="flex horizontal center">
            <button
              class="primary green"
              data-prepend-icon="calendar"
              @click=${this.createMeetingRoom}
            >
              ${msg("Create meeting link")}
            </button>
            <button
              class="outline blue"
              data-prepend-icon="video"
              @click=${this.createMeetingRoomImmediate}
            >
              ${msg("Start meeting")}
            </button>
          </nav>

          ${this.showMeetingUrl()}
        `;
      }
    }

    showMeetingUrl() {
      return html`
        <fieldset class="input-group ${!this.roomLink ? "invisible" : ""}">
          <input
            type="text"
            size="60"
            readonly
            autofocus
            value="${this.roomLink ?? ""}"
          />
          <button
            class="green round small"
            data-prepend-icon="copy"
            @click=${this.copyMeetingUrl}
          ></button>
        </fieldset>
      `;
    }

    async copyMeetingUrl() {
      await navigator.clipboard.writeText(this.roomLink);
      app.addToastMessage(msg("Meeting link copied to clipboard."));
    }

    async createMeetingRoomImmediate() {
      this.roomId = await this.createRoom();
      if (this.isValidRoomId(this.roomId))
        app.goTo(this.getMeetingUrl(this.roomId));
      else
        app.addToastMessage(msg("Error creating meeting room."), {
          type: "error",
        });
    }

    async createRoom() {
      const result = await this.createCallRoom(this.conversationId);
      return result.data.roomUrl.split("/").pop();
    }

    async createMeetingRoom() {
      const roomId = await this.createRoom();
      this.roomLink = this.getMeetingUrl(roomId);
    }

    async createCallRoom(conversationId) {
      try {
        return await this.#api.postData(
          `/conversations/${conversationId}/video-call-session`
        );
      } catch (error) {
        console.error(error);
        return null;
      }
    }

    getMeetingUrl(roomId) {
      if (this.conversationId && this.isValidRoomId(roomId))
        return new URL(
          `/messages/${this.conversationId}/${roomId}`,
          location.origin
        ).toString();

      return null;
    }

    getRoomUrl(roomId) {
      if (roomId && this.isValidRoomId(roomId))
        return `https://qogni.whereby.com/${roomId}`;

      return null;
    }

    isValidRoomId(roomId) {
      return roomId?.length === 36;
    }
  }
);
