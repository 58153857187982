// home.js

import { html, nothing } from "lit";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  WidgetEnabledPWAPage,
} from "../shared/pwa-page";
import { repeat } from "lit/directives/repeat.js";
import { msg, str } from "@lit/localize";
import { Converter } from "showdown";
import { unsafeHTML } from "lit/directives/unsafe-html.js";

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
};

export class PageHome extends OnboardedMixin(
  AuthenticatedMixin(WidgetEnabledPWAPage)
) {
  static get properties() {
    return {
      ...super.properties,
      tip: {
        type: Number,
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    if (this.widget) {
      this.tips = [
        html`<p>
          ${msg("You can send us feedback anytime, using the", {
            desc: "Message encouraging the user to send feedback; followed by feedback button.",
          })}
          <a href="/feedback">feedback tab</a> ${msg("in your personal menu!", {
            desc: "Indicates the location (in the menu) for sending feedback.",
          })}
        </p>`,
        html`<p>
          ${msg(
            "You can set your allergies in the health details panel in your",
            {
              desc: "Message instructing the user where to set their allergies in the health details section on home page",
            }
          )} <a href="/account">${msg("Account")}</a>
        </p>`,
      ];

      this.tip = getRandomInt(0, this.tips.length);
    }
  }

  renderPage() {
    const pageWidgets = app.config.widgets.map((i) => {
      return {
        settings: i?.run?.prototype?.getWidgetSettings
          ? new i.run().getWidgetSettings()
          : { priority: 0 },

        renderWidget: i.renderWidget,
      };
    });

    const widgets = [...pageWidgets, ...this.widgets].sort((w1, w2) => {
      return w1.settings.priority > w2.settings.priority ? -1 : 1;
    });

    return html` <widget-container class="masonry">
      ${repeat(widgets, (item) => {
        if (item.settings.hidden) return nothing;
        return html`${item.renderWidget(item.settings)}`;
      })}
    </widget-container>`;
  }

  getWidgetSettings() {
    return {
      ...super.getWidgetSettings(),
      priority: 898,
      hidden: true,
      title: msg("Did you know?"),
      cta: {
        icon: "info",
      },
    };
  }

  renderWidget() {
    return html`
      <section class="card .flex">
        <div class="tip">${this.tips[this.tip]}</div>
      </section>
    `;
  }

  // get locally defined widgets not attached to page classes.
  get widgets() {

    const returnArray = [];

    if (app.session.user.profile?.health_advice)
      returnArray.push(
        this.getWidget(
          msg(str`${"Health Advice"}`),
          new Converter().makeHtml(app.session.user.profile.health_advice),
          1000000
        )
      );

    return returnArray;
  }

  getWidget(title, body, prio) {
    return {
      settings: {
        priority: prio,
      },
      renderWidget: () => {
        return html`<div widget routekey priority="0">
          <h2 class="flex spread">${title}</h2>
          <section class="card scroll-y">${unsafeHTML(body)}</section>
        </div>`;
      },
    };
  }
}
