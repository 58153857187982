import { LitElement, html, nothing } from "lit";
import { repeat } from "lit/directives/repeat.js";

export class ManageUsers extends LitElement {
  createRenderRoot() {
    return this;
  }

  static get properties() {
    return {
      heading: { type: String },
      users: { type: Array },
      actions: { type: Array },
      badgeSelectors: { type: Array },

      allowAction: { type: Boolean }
    };
  }

  constructor() {
    super();
    this.users = [];
    this.actions = [];
    this.badgeSelectors = [];
    this.allowAction = false;
  }

  render() {
    if (this.users.length === 0) return nothing;

    const userId = app.session.user.id;
    const computedUsers = [...this.users].sort((a, b) => {
      if (a.id === userId) return -1;
      if (b.id === userId) return 1;
      return a.firstname.localeCompare(b.firstname);
    });

    return html`
      <section class="card">
        ${this.heading
          ? html`<h2 class="center">${this.heading}</h2>`
          : nothing}
        ${repeat(computedUsers, (user) => {
          const { id, firstname, lastname, profile_img_url } = user;
          const isMe = id === userId;
          return html`
            <user-item>
              <profile-picture
                name="${firstname} ${lastname}" 
                img="${profile_img_url}"
                uuid="${id}"
                size="50px" >
              </profile-picture>

              <div class="info">
                <h4>${firstname} ${lastname}</h4>
                ${id === userId
                  ? html`<badge-tag class="blue">You</badge-tag>`
                  : nothing}
                ${this.badgeSelectors.length === 0 
                  ? nothing 
                  : html`
                    ${repeat(this.badgeSelectors, (item) => {
                      if (!user[item]) return nothing;
                      return html`
                        <badge-tag class="blue">${user[item]}</badge-tag>
                      `
                    })}
                  `}
              </div>

              ${this.#renderActions(user, isMe)}
            </user-item>
          `;
        })}
      </section>
    `;
  }

  #renderActions(user, isMe) {
    if (!this.allowAction ||  this.actions.length === 0 || isMe) return nothing;

    return html`
      <div class="controls">
        <nav id="account" data-dropdown class="align-right img-button">
          <button title="Menu" class="simple">
            <svg-icon icon="meatballs"></svg-icon>
          </button>
          <menu>
            ${repeat(this.actions, (action) => {
              const { name, label, icon, theme, labelProvider } = action;
              return html`
                <li class="${theme}">
                  <a @click=${() => this.#onAction(name, user)}>
                    ${icon ? html`<svg-icon icon="${icon}" size="20px"></svg-icon>` : nothing}
                    ${label ? label : labelProvider(user)}
                  </a>
                </li>
              `;
            })}
          </menu>
        </nav>
      </div>
    `;
  }

  #onAction(name, user) {
    this.dispatchEvent(
      new CustomEvent("action", { detail: { action: name, user } })
    );
  }
}

customElements.define("manage-users", ManageUsers);
