import { html, nothing } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { UI as baseUI } from "@pwf/pure-work/flow/ui";
import { FlowOptions, FlowStepState } from "@pwf/pure-work/flow";
import { ApiRequest } from "./APIRequest";
import { Converter } from "showdown";
import "@pwf/pure-work/flow/ui";
import { QOGNI } from "./qogni";
import { msg, str } from "@lit/localize";
import { MasterDataDoamin } from "../domain/master-data-domain";

export const sexes = () => {
  return {
    1: msg("Male", { desc: "the sex male, NOT the gender." }),
    2: msg("Female", { desc: "the sex female, NOT the gender." }),
    3: msg("I don't want to share", {
      desc: "when asked for sex (NOT gender) you have this option",
    }),
  };
};

const booleanChoices = () => {
  return { Yes: msg("Yes"), No: msg("No") }
}

const personalDetailsChoices = () => {
  return { false: msg("Good summary!"), true: msg("Let me clarify") }
}

const afterOnboardingChoices = () => {
  return {
    bc: msg("Go to BrainCheck", {
      desc: "Never translate 'BrainCheck'. It's a part of our brand.",
    }),
    app: msg("Discover the app"),
  }
}

const foodChoiceItems = () => {
  const obj = {};
  const foodPreferences = MasterDataDoamin.singleton.foodPreferences ?? [];
  const currentLanguage = app.session.user?.language;

  foodPreferences.map((i) => {
    obj[i.id] =
      i?.translations.find((e) => e.locale === currentLanguage)?.value ??
      i.name;
  });
  return obj;
};

const allergiesAndIntoleranceItems = () => {
  const allergens = MasterDataDoamin.singleton.allergens
  const currentLanguage = app.session.user?.language;
  const filteredAllergens = allergens.filter(a => !a.name.includes('Celiac'));
  const computedAllergens = filteredAllergens.map(a => {
    const label = a?.translations.find(e => e.locale === currentLanguage)?.value ?? a.name;
    return {value: a.id, label}
  })

  return computedAllergens;
}

export const selectOne = (step) => {
  let items;

  if (typeof step.options.items === "function") {
    items = step.options.items();
  } else if (
    Array.isArray(step.options.items) ||
    typeof step.options.items === "object"
  ) {
    items = step.options.items;
  }

  if (typeof items === "object") {
    items = Object.entries(items).map(([key, name]) => {
      return { value: key, text: name };
    });
  }

  return html`<radio-group
    name="step"
    class="wide-on-mobile"
    .value=${step.value}
    .items=${items}
  ></radio-group>`;
};

export const selectLanguage = (step) => {
  const items = Object.entries(QOGNI.meta.languages);
  return html`<radio-group id="languages">
    ${repeat(items, ([key, value]) => {
      return html`<label>
        <input
          .checked=${key === step.value}
          name="step"
          value="${key}"
          type="radio"
        />
        <span>
          <svg class="icon" xmlns="http://www.w3.org/2000/svg">
            <use href="/assets/img/intl-flags.svg#${key}"></use>
          </svg>

          ${msg(str`${value}`)}
        </span>
      </label>`;
    })}
  </radio-group>`;
};

const selectMultiple = (step) => {
  let items = step.options.items;
  if (typeof items === "function") {
    items = items();
  }

  return html`<multi-select
    .value=${step.value}
    ?autosort=${step.options.autosort}
    max="${step.options.max ?? 999}"
    name="step"
  >
    ${repeat(items, (item) => {
      const label = typeof item === 'string' ? item : item.label;
      const value = typeof item === 'string' ? item : item.value;
      return html`<label>
        <input
          class="variant2"
          type="checkbox"
          name="step"
          value="${value}"
          ?checked=${step.value?.includes(value)}
        />
        <span class="data-label">${label}</span>
      </label>`;
    })}
  </multi-select>`;
};

const textField = (step) => {
  const _N = nothing;

  return html`<input
    id="${step.id || _N}"
    autocomplete="${step.options.autocomplete ?? "off"}"
    name="step"
    type="${step.options.type || _N}"
    value="${step.value || _N}"
    class="${step.options.class || _N}"
    placeholder="${typeof step.options.placeholder === 'function' ? step.options.placeholder() : step.options.placeholder || _N}"
    ?required=${!!(step.options.required ?? true)}
    ?hidden=${!!(step.options.hidden)}
    ?readonly="${!!(step.options.readonly)}"
    pattern="${step.options.pattern || _N}"
    list="${step.options.list || _N}"
    max="${step.options.max || _N}"
    maxlength="${step.options.maxlength || _N}"
    minlength="${step.options.minlength || _N}"
    min="${step.options.min || _N}"
    step="${step.options.step || _N}"
    autocapitalize="${step.options.autocapitalize || _N}"
    accesskey="${step.options.accesskey || _N}"
    lang="${step.options.lang || _N}"
    role="${step.options.role || _N}"
    slot="${step.options.slot || _N}"
    spellcheck="${step.options.spellcheck || _N}"
    style="${step.options.style || _N}"
    tabindex="${step.options.tabindex || _N}"
    title="${step.options.title || _N}"
    translate="${step.options.translate || _N}"
    @input=${step.options.on?.input || _N}
    @keydown=${step.options.on?.keydown || _N}
    @keyup=${step.options.on?.keyup || _N}

  />`;
}

const selectDate = (step) => {
  return html`<date-text
    name="step"
    .value="${step.value ?? ""}"
    format="dd-mm-yyyy"
  ></date-text>`;
};



const baseDate = {
  renderInput: selectDate,
};

const baseMultiple = {
  isMany: true,
  renderInput: selectMultiple,
};

const boolSelect = {
  renderInput: selectOne,
  items: booleanChoices,
  transform: {
    out: (value) => value === "Yes",
  },
};

export const UI = {
  ...baseUI,
  date: baseDate,
  firstName: {
    renderInput: textField,
    type: "text",
    store: "user.firstname",
    placeholder: () => msg("Enter your firstname")
  },
  lastName: {
    renderInput: textField,
    type: "text",
    store: "user.lastname",
    placeholder: () => msg("Enter your lastname")
  },
  birthdate: {
    ...baseDate,
    name: "birthdate",
    store: "user.date_of_birth",
  },
  weight: {
    type: "number",
    placeholder: "in kg",
    min: 30,
    max: 500,
    store: "user.body_weight",
    transform: {
      in: (value) => {
        return Math.round(value);
      }
      
    },
  },
  height: {
    type: "number",
    placeholder: "in cm",
    store: "user.body_length",
    min: 50,
    max: 280,
    transform: {
      in: (value) => {
        return Math.round(value);
      },
    },
  },
  language: {
    renderInput: selectLanguage,
    items: QOGNI.meta.languages,
    store: "user.language",
  },
  langDisclaimer: {
    type: "text-block",
    autoContinue: 5000,
    renderInput: (step) => {
      return html` <h3>${step.text}</h3> `;
    },
  },
  sexe: {
    renderInput: selectOne,
    items: sexes,
    store: "user.sexe",
    transform: {
      out: (value) => {
        return Number(value);
      },
    },
  },
  foodChoices: {
    renderInput: selectOne,
    items: foodChoiceItems,
    store: "user.food_preference_id",
    transform: {
      in: () => {
        return app.session?.user?.food_preference_id ?? '';
      },
    },
  },
  hasAllergies: {
    ...boolSelect,
    transform: {
      ...boolSelect.transform,
      in: () => {
        return app.session.user?.allergens?.length > 0
          ? "Yes"
          : "No";
      },
    },
    store: "user.hasAllergies",
  },
  allergies: {
    ...baseMultiple,
    items: allergiesAndIntoleranceItems,
    store: "user.allergens",
    transform: {
      in: () => {
        const masterAllergens = MasterDataDoamin.singleton.allergens;
        const celiacId = masterAllergens.find(ms => ms.name.includes('Celiac'))?.id; 
        const allergens = app.session.user?.allergens;
        const filtered = allergens.filter(e => typeof e === 'object' ? e.id !== celiacId : e !== celiacId)
        return filtered.map(i => typeof i === 'object' ? i.id : i) ?? [];
      },
      out: (value) => {
        const masterAllergens = MasterDataDoamin.singleton.allergens;
        const allergens = app.session.user.allergens;
        const celiacId = masterAllergens.find(ms => ms.name.includes('Celiac'))?.id;
        const glutenId = masterAllergens.find(ms => ms.name.includes('Gluten'))?.id;
        const hasCeliac = !!allergens.find(i => typeof i === 'object' ? i.id === celiacId : i === celiacId);
        const hasGluten = !!value.find(i => i === glutenId);

        return [...value, ... hasGluten && hasCeliac ? [celiacId] : []];
      }
    },
  },
  celiac: {
    ...boolSelect,
    store: "user.celiacDisease",
    backTarget: false,
    transform: {
      in: () => {
        const masterAllergens = MasterDataDoamin.singleton.allergens;
        const allergens = app.session.user.allergens;
        const celiacId = masterAllergens.find(ms => ms.name.includes('Celiac'))?.id;
        const hasCeliac = !!allergens.find(i => typeof i === 'object' ? i.id === celiacId : i === celiacId);
        
        return hasCeliac ? "Yes" : "No";
      },
      out: (value) => {
        return value === "Yes";
      },
    },
  },
  job: {
    renderInput: (step) => {
      const selectAc = (text) => {
        const ac = document.querySelector(
          "flow-ui .flow-step jobs-autocomplete"
        );
        const input = ac.querySelector("input[name=step]");

        if ((text ?? null) !== null) {
          input.value = text;
        }

        setTimeout(() => {
          input.focus();
        }, 200);
      };
      setTimeout(selectAc, 500);

      const noJobChange = (e) => {
        if (e.target.value) {
          selectAc("<not applicable>");
        } else {
          selectAc("");
        }
      };
      return html`
        <fieldset>
          <jobs-autocomplete
            name="step"
            required
            placeholder=${msg("Job name")}
            value="${step.value ?? ""}"
          ></jobs-autocomplete>
        </fieldset>
        <fieldset>
          <switch-check
            @change=${noJobChange}
            text="${msg("I don't currently have a job")}"
          ></switch-check>
        </fieldset>
      `;
    },
    store: "user.job",
  },
  healthTopics: {
    ...baseMultiple,
    max: 3,
    autosort: true,
    items: [
      "Becoming Fitter",
      "Boosting immunity",
      "Balancing hormones",
      "Improving sleep quality",
      "Supporting heart health",
      "Moderation alcohol consumption",
      "Enhancing mental resilience",
      "Achieving a calmer gut",
      "Improving work-life balance",
      "Quitting smoking",
      "Reducing stress-levels",
      "Boosting energy levels",
      "Reaching a healthy weight",
    ],
    transform: {
      in: () => {
        return app.session.user.interest_topics?.map((x) => x.name) ?? [];
      },
    },

    scores: {
      1: "Well, we need to work on your health then!",
      2: "Enough room for improvement then!",
      3: "That's on the average side. Let's try and see what Qogni can do!",
      4: "You're in good shape! Keep up the good work!",
      5: "Excellent! You're a fit person!",
    },

    store: "user.topics",
  },
  video: {
    renderInput: (step) => {
      return html`<video
        crossorigin="anonymous"
        width="90%"
        height="auto"
        controlslist="nodownload"
        controls
        autoplay
      >
        <source type="video/mp4" src="${step.options.url}" />
      </video>`;
    },
  },

  needPersonalDetails: {
    renderInput: selectOne,
    items: personalDetailsChoices,
    transform: {
      out: (value) => {
        return value === "true";
      },
    },
  },

  intention: {
    name: "intention",
    renderInput: baseUI.longtext.renderInput,
    placeholder: msg("Add more personal details") + "...",
    class: "large",
    maxlength: 3000,
    store: "user.health_intention",
  },

  healthRating: {
    renderInput: (step) => {
      const healthScores = [
        msg("Not set", {
          desc: "Level of which the user will classify their health status, this is used in a grade system from 1 to 5, where 1 is worst and 5 is best. Current value: not set",
        }),
        msg("Bad", {
          desc: "Level of which the user will classify their health status, this is used in a grade system from 1 to 5, where 1 is worst and 5 is best. Current value: bad, 1",
        }),
        msg("Mediocre", {
          desc: "Level of which the user will classify their health status, this is used in a grade system from 1 to 5, where 1 is worst and 5 is best. Current value: mediocre, 2",
        }),
        msg("Average", {
          desc: "Level of which the user will classify their health status, this is used in a grade system from 1 to 5, where 1 is worst and 5 is best. Current value: average, 3",
        }),
        msg("Good", {
          desc: "Level of which the user will classify their health status, this is used in a grade system from 1 to 5, where 1 is worst and 5 is best. Current value: good, 4",
        }),
        msg("Great!", {
          desc: "Level of which the user will classify their health status, this is used in a grade system from 1 to 5, where 1 is worst and 5 is best. Current value: great, 5",
        }),
      ];

      const num = (txt) => {
        const n = Number(txt);
        return isNaN(n) ? 0 : n;
      };

      return html` <fieldset>
        <legend data-rating="${num(step.value)}" class="health-info">
          ${healthScores[num(step.value)]}
        </legend>
        <social-rating
          name="step"
          @change=${(e) => {
            const l = e.target.closest("fieldset").querySelector("legend");
            l.setAttribute("data-rating", e.target.value);
            l.innerHTML = healthScores[num(e.target.value)];
          }}
          value="${step.value ?? 0}"
        >
        </social-rating>
      </fieldset>`;
    },
    transform: {
      out: (value) => {
        return Number(value);
      },
    },
    store: "user.health_rating",
  },
  afterOnboarding: {
    renderInput: selectOne,
    items: afterOnboardingChoices,
  },
};

/**
 * Flow Wrapping class
 */
export class QogniFlow {
  #root;
  #api = ApiRequest.factory();
  #options;

  constructor(options) {
    options = options ?? {};
    if (!options.start || typeof options.start !== "function")
      throw new Error("No entry point provided");

    this.#root = options.root;

    options.flowType = options.flowType ?? "full-page";

    options.flowId = options.flowId ?? options.start.name.split(" ").pop();
    this.#options = {
      on: {
        ...(options?.on ?? {}),
      },
      ...options,
    };
    this.#createSession();
  }

  async #createSession() {
    const request = await this.#api.postData("/users/me/sessions");
    sessionStorage.setItem("be-session", request.data.id);
  }

  render() {
    return html`
      <flow-ui
        type="${this.#options.flowType}"
        .options=${this.onboardWorkflowOptions}
      ></flow-ui>
    `;
  }

  // Flow options
  get onboardWorkflowOptions() {
    const options = new FlowOptions(
      this.#options.flowId,
      this.#options.start,
      (wf) => {
        this.workflow = wf;

        this.#tryOn("init", wf);

        wf.install("askAI", this.askAI.bind(this), {
          backTarget: false,
        })
          .install("reward", this.rewardUI.bind(this), {
            backTarget: false,
          })

          .on("step-complete", (e) => {
            const step = e.detail.step;
          })
          .on("workflow-ended", () => {
            if (!this.#tryOn("end")) app.goTo("/");
          })

          .on("step-rendered", (e) => {
            const step = e.detail;
            const stepElement = this.#root.querySelector(
              `[data-step="${step.key}"]`
            );
            if (stepElement) {
              // work with rendered ui
              const form = stepElement.querySelector("form");
              if (form) {
                form.querySelector("button.primary")?.classList.add("green");
                form.querySelector("button.secondary")?.classList.add("white");
              }
            }
          })
          .on("step-ui-rendered", e=>{

            if(this.#options.flowType !== "full-page"){
              e.preventDefault();
            }
          })
          ;
      }
    );
    options.useBroker = true;

    options.strings.continue = msg("Continue");
    options.strings.back = msg("Back");

    //options.state = new BrokerFlowStateHandler();

    return options;
  }

  // executes event hook if it exists on #options.on object.
  #tryOn(eventName, wf) {
    if (typeof this.#options?.on[eventName] === "function") {
      this.#options?.on[eventName](wf);
      return true;
    }
    return false;
  }

  async askAI(step) {
    step.options.format = "string";

    step.render = () => {
      if (step.state === FlowStepState.Completed)
        return html`${msg("Ready")}...`;

      return html`<type-writer
        .items=${[
          msg("Just a second..."),
          msg("This will not take long..."),
          msg("Coming up with an answer shortly..."),
          msg("Sorry, this takes a bit longer than expected..."),
        ]}
      ></type-writer>`;
    };

    const resolve = (data) => {
      const fi = document.querySelector("[data-flow-inner]");
      if (fi) fi.classList?.toggle("busy", false);
      this.workflow.busy = false;
      step.resolve(data);
    };

    document.querySelector("[data-flow-inner]").classList.toggle("busy", true);
    this.workflow.busy = true;

    const result = await this.#api.postData(
      `/users/me/sessions/${sessionStorage.getItem("be-session")}/ask`,
      {
        question: `${step.options.topic}

        NOTE: Your answer must be in the language '${app.session.user.language}'`,

        scope: step.options.scope ?? false,
        ...step.options,
      }
    );
    let returnValue = result.data?.message;
    if (step.options.format === "string" && step.options.html) {
      returnValue = new Converter().makeHtml(returnValue);
    }
    resolve(returnValue);
  }

  async rewardUI(step) {
    step.render = () =>
      html`<reward-animation>${step.topic}</reward-animation>`;

    step.on("continue-request", (e) => {
      e.continue();
    });
    setTimeout(() => {
      step.resolve();
    }, 1000);
  }
}
