import "@lit-labs/virtualizer";
import { msg } from "@lit/localize";
import "@qogni-technologies/design-system/src/components/base/modal-dialog.js";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { LitElement, html, nothing } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { PostDomain } from "../../domain/post-domain";

export class ReactionsListDialog extends LitElement {
  #domain;

  #fullList = [];

  createRenderRoot() {
    return this;
  }

  static get properties() {
    return {
      open: { type: Boolean },
      postId: { type: String },
      commentId: { type: String },

      stats: { type: Object },

      loading: { type: Boolean },
      list: { type: Array },

      _computedList: { type: Array },
      _pagination: { type: Object },
      _currentTab: { type: String },
    };
  }

  constructor() {
    super();
    this.animation = "slideUp";
    this.#domain = new PostDomain();
    this._computedList = [];
    this._currentTab = "all";
  }

  connectedCallback() {
    super.connectedCallback();

    this.#getLikes();
  }

  async #getLikes() {
    const task = async () => {
      this.loading = true;
      const code =
        this._currentTab === "like"
          ? 0
          : this._currentTab === "love"
          ? 1
          : this._currentTab === "idea"
          ? 2
          : undefined;

      
      let res;

      if (this.commentId) {
        res = await this.#domain.getCommentLikes(this.postId, this.commentId, {
          ...(this._nextCursor && { cursor: this._nextCursor }),
          ...(code !== undefined && { filter_type: code }),
        })
      } else {
        res = await this.#domain.getPostLikes(this.postId, {
          ...(this._nextCursor && { cursor: this._nextCursor }),
          ...(code !== undefined && { filter_type: code }),
        });
      }
      this.list = res.data;
      this._pagination = res.pagination;
      this._nextCursor = this._pagination?.next_cursor;
      this.loading = false;
    };

    await Task.run(task);
  }

  #onLoadMoreClick() {
    this.#getLikes();
  }

  #onTabChange(e) {
    const tab = e.detail;
    this._currentTab = tab.name;
    this._computedList = [];
    this.#fullList = [];
    this.#getLikes();
  }

  #onDialogClose() {
    this.dispatchEvent(new Event("close"));
  }

  willUpdate(changeProps) {
    if (changeProps.has("list") && this.list.length !== 0) {
      this.#fullList = [...this.#fullList, ...this.list];
      this._computedList = [...this.#fullList];
    }
  }

  render() {
    return html`
      <modal-dialog open @close=${this.#onDialogClose}>
        <span slot="title">${msg("Post Reactions")}</span>
        <svg-icon
          slot="title-action"
          icon="close"
          size="16px"
          dismiss
        ></svg-icon>

        ${this.#renderContent()}
      </modal-dialog>
    `;
  }

  #renderContent() {
    const totalReactions = this.stats.like + this.stats.love + this.stats.idea;

    return html`
      ${this.#renderTabs(totalReactions)} ${this.#renderList(totalReactions)}
      ${this._nextCursor
        ? html`<button class="tiny wide" @click=${this.#onLoadMoreClick}>
            ${msg("Show more", {
              id: "show-more",
              desc: "Label for a button that loads or reveals additional content when clicked.",
            })}
          </button>`
        : nothing}
    `;
  }

  #renderTabs(total) {
    let tabs = [{ label: `All ${total}`, name: "all" }];
    const keysToInclude = ["like", "love", "idea"];

    keysToInclude.forEach((e) => {
      if (e in this.stats && this.stats[e]) {
        tabs.push({
          render: () =>
            html`<svg-icon
                style="margin-right: var(--gutter-tiny);"
                icon=${e}
                size="16px"
              ></svg-icon
              >${this.stats[e]}`,
          name: e,
        });
      }
    });

    return html`<tabs-indicator
      .value=${this._currentTab}
      .tabs=${tabs}
      @change=${this.#onTabChange}
    ></tabs-indicator>`;
  }

  #renderList(total) {
    if (this._computedList.length === 0) {
      const computedTotal =
        this._currentTab === "all" ? total : this.stats[this._currentTab];
      const shimmerData = Array.from(
        { length: computedTotal > 25 ? 25 : computedTotal },
        () => ({})
      );
      return html`${repeat(shimmerData, () => {
        return html`
          <section class="card shimmer">
            <div class="profile-pic-wrapper">
              <app-shimmer class="image circle"></app-shimmer>
            </div>
            <div class="info">
              <app-shimmer></app-shimmer>
            </div>
          </section>
        `;
      })}`;
    }

    return html`
      <lit-virtualizer
        .items=${this._computedList}
        .renderItem=${this.#renderItem.bind(this)}
      ></lit-virtualizer>
    `;
  }

  #renderItem(like) {
    const { user, type_code } = like;
    const { firstname, lastname, id, profile_img_url } = user;

    return html`
      <section class="card">
        <div class="profile-pic-wrapper">
          <profile-picture
            name="${firstname} ${lastname}"
            img="${profile_img_url}"
            uuid="${id}"
            link=${`/profile/${id}`}
            size="40px"
          >
          </profile-picture>
          <span class="reaction-icon ${type_code}">
            <svg-icon icon=${type_code} size="12px"></svg-icon>
          </span>
        </div>
        <div class="info">
          <a href="/profile/${id}">
            <h4>${firstname} ${lastname}</h4>
          </a>
        </div>
      </section>
    `;
  }
}

customElements.define("reactions-list-dialog", ReactionsListDialog);
