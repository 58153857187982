import { css, html, LitElement, nothing } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { msg, str } from "@lit/localize";
import { Broker } from "@pwf/pure-work/broker";
import { QOGNI } from "../qogni";
import { isSubclass, kebabToWords } from "../common";
import { FlowPage } from "../flow-page";

/**
 * Global menu bar
 */
customElements.define(
  "global-menu",
  class GlobalMenu extends LitElement {
    #all = [];

    static get properties() {
      return {
        appearance: { type: String, attribute: true, reflect: true },
        profile: { type: Object },
        activeRoute: { type: String, attribute: "active-route" },
      };
    }

    static get styles() {
      return [
        css`
          @keyframes fadein {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }

          :host {
            pointer-events: none;
            --c: var(--color-primary-300);
            --side-bg: #e7e3de;
            --shadow: 1px 1px 30px rgba(0, 0, 0, 0.1);
            --gradient-shadow: linear-gradient(
              45deg,
              var(--color-primary-400),
              var(--color-accent-200),
              var(--color-accent-100),
              var(--color-accent-400),
              var(--color-accent-300)
            );
            padding-left: 5px;
            padding-right: 10px;
            padding-top: 1rem;
            z-index: 99;
            display: block;
            position: fixed;
            top: 0px;
            left: 0px;
            width: 260px;
          }

          :host([appearance="full"]) {
            background-color: var(--side-bg);
          }

          .popover {
            display: none;
            margin-left: 50px;
            background-color: var(--side-bg);
            &.active {
              display: block;
            }

            menu {
              li {
                a[href] {
                  width: 200px;
                }
              }
            }
          }

          nav {
            height: 100dvh;
            overflow-y: hidden;
            padding-left: 0.5rem;
            padding-right: 20px;
            display: grid;
            grid-template-columns: 60px 1fr;
            gap: 1rem;
            grid-template-areas:
              "featured main"
              "unused main"
              "settings settings";

            li {
              figure {
                margin: 2px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
              }
            }

            [data-group="featured"] {
              li[data-id="page_ai_buddy"] {
                position: relative;

                &:before,
                &:after {
                  content: "";
                  position: absolute;
                  border-radius: 100%;
                  top: -4px;
                  left: -4px;
                  background: var(--gradient-shadow);
                  background-size: 400%;
                  width: calc(100% + 8px);
                  height: calc(100% + 8px);
                  z-index: -1;
                  animation: animate 10s linear infinite;
                }
              }
            }
          }

          nav[data-appearance="minimal"] {
            [data-group="settings"] {
              menu {
                flex-direction: column;
              }

              .popover {
                menu {
                  flex-direction: row;
                  flex-wrap: wrap;
                }
              }
            }
          }

          .group-name {
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.25);
            margin: 0;
          }

          [data-text] {
            text-transform: none;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: rgba(0, 0, 0, 0.55);
            font-weight: 600;
            font-size: 0.9rem;
            display: inline-block;
            margin-left: 0.5rem;
            padding-right: 1rem;
          }

          [data-group="featured"] {
            grid-area: featured;
            border-right: 2px solid transparent;
            border-image: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0.15),
                rgba(0, 0, 0, 0)
              )
              1;

            [data-text] {
              display: none;
            }
            .group-name {
              display: none;
            }
          }

          #main-group {
            animation: fadein 0.2s linear;
            grid-area: main;
            display: flex;
            flex-direction: column;

            gap: 1rem;

            section {
              margin-bottom: 1rem;

              a[href] {
                min-width: 150px;
              }
            }
          }

          [data-group="settings"] {
            grid-area: settings;
            align-self: flex-end;
            margin-bottom: 30px;
            [data-text] {
              display: none;
            }
            .group-name {
              display: none;
            }

            .popover {
              bottom: 0;
              max-width: 200px;
            }
          }

          [data-group="settings"],
          [data-group="featured"] {
            a[href] {
              overflow: hidden;
              padding: 0;
            }
          }

          menu,
          li {
            list-style: none;
            margin: 0px;
            display: flex;
            padding: 0;
            --c: var(attr(data-color));
          }

          menu {
            display: flex;
            gap: 0.5rem;
            flex-wrap: wrap;
          }

          figure {
            padding: 0;
            margin: 0;
          }

          a:link,
          a:visited,
          button {
            pointer-events: all;
            -webkit-tap-highlight-color: transparent;
            padding: 0;
            border-radius: var(--radius-large);
            text-decoration: none;
            color: var(--color-text-000);
            background-color: var(--color-primary-100);
            border: 0px;
            outline: none;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            transition: background var(--transition-duration) ease-in-out,
              color var(--transition-duration) ease-in-out;

            &:active {
              transform: translate(2px, 2px);
              transition: all 0.1s ease-in-out;
            }

            &:hover {
              box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
            }
          }

          :host([appearance="minimal"]) {
            [data-group="featured"] {
              a:link,
              a:visited,
              button {
                border-radius: 100%;
              }

              .popover {
                a:link,
                a:visited,
                button {
                  border-radius: var(--radius-large);
                }
              }
            }
          }

          .popover {
            a:link,
            a:visited,
            button {
              background-color: var(--color-primary-200);
            }
          }

          [data-id] {
            figure {
              aspect-ratio: 1/1;
              background-color: var(--c);
            }
            .text {
              font-weight: 800;
            }
            svg-icon {
              --icon-size: 24px;
              --icon-fill-color: white;
            }
            a:hover,
            button:hover,
            a.active {
              background-color: var(--c);
              figure {
                background-color: white;
              }
              [data-text] {
                color: white;
              }
              svg-icon {
                --icon-fill-color: var(--c);
              }
            }
          }

          li:has(.popover) {
            position: relative;
          }

          .popover {
            zoom: 0.8;
            position: absolute;
            border-radius: var(--radius-large);
            border: 0;
            outline: none;
            padding: var(--gutter-tiny);
            flex-direction: column;
            gap: 8px;
            opacity: 1;
            transform: scale(1) translateY(10px);
            inset: unset;
            box-shadow: var(--shadow);

            [data-text] {
              display: inline-block;
            }
          }
        `,
        // mobile view
        css`
          @media only screen and (max-width: 600px) {
            :host {
              top: unset;
              left: -20px;
              width: 100vw;
              height: 70px;
              bottom: 20px;
            }

            menu {
              display: flex;
              flex-direction: row;
              gap: 1rem;
            }

            [popover] {
              menu {
                max-width: 80vw;
                flex-wrap: wrap;
              }
            }

            :host([appearance="full"]) {
              top: 0;
              left: 0;
              width: 100vw;
              height: 100vh;
              background-color: var(--side-bg);
              padding: 10px;
              transition: background-color 0.3s ease-in-out;
            }

            [data-group="featured"] {
              display: flex;
              width: 100vw;
              justify-content: center;

              border-image: unset;
              position: fixed;
              bottom: 25px;
              transition: bottom 0.5s cubic-bezier(0.05, -0.23, 1, 1.32);
              left: 0;
              right: 0;
              top: unset;

              .popover {
                right: 0;
                bottom: 70px;
              }

              menu {
                justify-content: center;
              }
            }

            [window-scroll="down"] {
              [data-group="featured"] {
                bottom: -100px;
                transition: bottom 0.4s ease-in-out;
              }
            }

            nav {
              grid-template-columns: 100vw;
              grid-template-rows: 70px;
              grid-template-areas: "featured";
              position: relative;
            }

            nav[data-appearance="full"] {
              grid-template-columns: unset;
              grid-template-rows: 1fr 120px 1fr;

              grid-template-areas:
                "main"
                "settings"
                "featured";

              [data-group="featured"] {
                align-self: flex-end;
                justify-self: center;
              }
              [data-group="settings"] {
                justify-self: center;
                align-self: flex-end;

                .popover {
                  bottom: 70px;
                  left: -50px;
                }
              }
            }
          }
        `,
        css`
          @keyframes animate {
            0% {
              background-position: 0 0;
            }
            50% {
              background-position: 300% 0;
            }
            100% {
              background-position: 0 0;
            }
          }
        `,
      ];
    }

    constructor() {
      super();
      this.appearance = "minimal";
      this.activeRoute = "/";

      Broker.instance.subscribe("user-update", (u) => {
        this.profile = u;
      });
      Broker.instance.subscribe("window-scroll", (dir) => {
        this.renderRoot
          .querySelector("nav")
          ?.setAttribute("window-scroll", dir);
      });
    }

    connectedCallback() {
      super.connectedCallback();

      document.addEventListener("click", this.tryStartClose.bind(this));

      this.#all = Object.values(app.config.routes).filter((x) => {
        if (x.path === "/account") {
          x.dropdown = {
            id: "account",
            html: this.renderAccountPopover(),
          };
        }
        return x.config?.menu;
      });
    }

    disconnneCallback() {
      super.disconnectedCallback();

      document.removeEventListener("click", this.tryStartClose.bind(this));
    }

    // uses grid with grid-template-areas to tune display to view
    render() {
      return html`
        <nav
          @click=${this.navClick}
          class="${this.class}"
          data-appearance="${this.appearance}"
        >
          ${this.renderGroup("featured")} ${this.renderMain()}
          ${this.renderGroup("settings")}
        </nav>
      `;
    }

    renderMain() {
      if (this.appearance === "full")
        return html` <section id="main-group">
          ${this.renderGroup("tools")} ${this.renderGroup("solutions")}
          ${this.renderGroup("social")} ${this.renderGroup("more")}
        </section>`;
    }

    sort(items) {
      const position = "side";
      return items.sort((a, b) => {
        const aSettings = a.config?.menu[position],
          aIndex = aSettings?.index;
        const bSettings = b.config?.menu[position],
          bIndex = bSettings?.index;

        return aIndex > bIndex ? 1 : -1;
      });
    }

    updated(changed) {
      const newProps = {};

      for (const prop of changed) {
        const key = prop[0];
        newProps[key] = this[key];
      }

      Broker.instance.publish("global-menu-change", newProps);
    }

    navClick(e) {
      if (
        !e.target.closest(".popover.active") &&
        !e.target.closest("[data-popover]")
      ) {
        setTimeout(() => {
          this.closeActivePopups();
        }, 0);
      }

      const link = e.target.closest("[href]");

      const path = link?.getAttribute("href");

      if (!path) e.stopPropagation();

      // prevent refresh of current page
      if (path === location.pathname) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }

      if (link && link.getAttribute("href")) {
        const pageClass = app.config.routes[path]?.run;

        if (
          link.classList.contains("flow-page") ||
          isSubclass(pageClass, FlowPage)
        ) {
          // workaround for Chromium/Windows browser crash
          e.preventDefault();

          app.goTo(path, {
            force: path !== '/ai-buddy',
          });
        } else this.startClose();
        return;
      }

      if (link) {
        e.preventDefault();

        const key = link.closest("[data-id]").getAttribute("data-id");

        switch (key) {
          case "menu-toggle":
            this.toggle();
            break;
          case "account-view":
            app.goTo(
              `/profile/${app.session.user?.slug ?? app.session.user?.id}`
            );
            this.startClose();
            break;
          case "account-update":
            app.goTo("/onboarding/health");
            this.startClose();
            break;

          case "account-signout":
            app.signOut(e);

            break;
        }
      }
    }

    /**
     * Starts closing to minimal appearance
     */
    startClose() {
      setTimeout(() => {
        this.appearance = "minimal";
      }, 10);

      this.closeActivePopups();
    }

    closeActivePopups() {
      this.renderRoot.querySelectorAll(".popover.active").forEach((i) => {
        i.classList.toggle("active", false);
      });
    }

    // called when clicking outside the web component
    tryStartClose(e) {
      if (!this.renderRoot.contains(e.target)) this.startClose();
    }

    // toggle between 'full' and 'minimal' appearance
    toggle() {
      this.appearance = this.appearance === "full" ? "minimal" : "full";
    }

    renderGroup(group) {
      if (group === "other") return;

      return html` <section data-group="${group}">
        <h3 class="group-name">
          ${msg(str`${group}`)}
        </h3>
        ${this.renderGroupChildren(group)}
      </section>`;
    }

    renderGroupChildren(name) {
      const items = this.getGroupChildren(name);

      return html`
        <menu>
          ${repeat(items, (item) => {
            return html`${this.renderMenuItem(item)}`;
          })}
        </menu>
      `;
    }

    /**
     * Determines whether a menu item should be displayed in the menu.
     * @param {Object} item
     * @returns { Boolean } false when the menu item should not be displayed
     */
    isInContext(item) {
      if (!app.session?.user?.organisation_id) {
        if (item.config?.company) return false;
      }
      return true;
    }

    /**
     * Gets the children of a menu group.
     * @param {String} name
     * @returns { Array }
     */
    getGroupChildren(name) {
      switch (name) {
        case "featured":
          return [
            {
              id: "menu-toggle",
              name: this.appearance === "minimal" ? "Expand menu" : "Close",
              config: {
                icon: this.appearance === "minimal" ? "hamburger" : "close",
                color: "red",
              },
            },
            ...this.sort(
              this.#all.filter((x) => x.config?.menu?.side?.featured)
            ),
          ];

        case "more":
          // return [{
          //   id: "menu-more",
          //   name: "More",
          //   config: { icon: "meatballs", color: "yellow" },
          //   dropdown: {
          //     id: "more",
          //     html: this.renderMorePopover(),
          //   },
          // }]
          return this.getGroupChildren("other");
        default:
          return this.#all.filter((x) => x.config?.menu?.side?.group === name);
      }
    }

    renderMorePopover() {
      const items = this.getGroupChildren("other");
      return html`<menu>
        ${repeat(items, (item) => {
          return html`${this.renderMenuItem(item)}`;
        })}
      </menu>`;
    }

    renderAccountPopover() {
      const items = [
        {
          path: "/account",
          name: "Edit Account",
          config: { icon: "user-role", color: "yellow" },
        },
        {
          id: "account-view",
          name: "Public Profile",
          config: { icon: "account", color: "blue" },
        },
        {
          path: "/settings",
          name: "Settings",
          config: { icon: "settings", color: "black" },
        },
        {
          path: "/saved-items",
          name: "Saved Items",
          config: { icon: "save", color: "yellow" },
        },

        {
          //id: "account-update",
          name: "Health Settings",
          path: "/onboarding/health",
          config: { icon: "play", color: "green", isFlowPage: true },
        },

        {
          id: "send-feedback",
          path: "/feedback",
          name: "Send Feedback",
          config: { icon: "like", color: "blue" },
        },
        {
          id: "account-signout",
          name: "Sign out",
          path: "/sign-out",
          config: { icon: "account", color: "gray" },
        },
      ];
      return html`<menu>
        ${repeat(items, (item) => {
          return html`${this.renderMenuItem(item)}`;
        })}
      </menu>`;
    }

    renderMenuItem(item) {
      if (!this.isInContext(item)) return nothing;
      const color = QOGNI.meta.colors[item.config?.color];
      return html`
        <li
          style="--c: var(${color})"
          data-index="${item.config?.menu?.side?.index}"
          data-id="${item.id}"
          title="${item.description ?? kebabToWords(item.name)}"
        >
          ${this.renderItemBody(item)}
        </li>
      `;
    }

    renderItemBody(item) {
      if (item.dropdown) {
        const togglePopup = (e) => {
          const button = e.target.closest("[data-popover]");
          const popup = this.renderRoot.querySelector(
            `#${button.getAttribute("data-popover")}`
          );
          popup.classList.toggle("active");
        };
        return html`<button
            @click=${togglePopup}
            data-popover="${item.dropdown.id}"
          >
            <figure>${this.renderGraphic(item)}</figure>
            <span data-text="">${this.getItemName(item)}</span>
          </button>
          <div id="${item.dropdown.id}" class="popover">
            ${item.dropdown.html}
          </div> `;
      }
      return html`<a
        class="${this.getItemClass(item)}"
        href="${item.path || ""}"
      >
        <figure>${this.renderGraphic(item)}</figure>
        <span data-text="">${this.getItemName(item)}</span>
      </a>`;
    }

    getItemName(item) {
      const name = item.title ?? kebabToWords(item.name);
      return item?.translatedName ? item.translatedName() : name;
    }

    getItemClass(item) {
      let active = item.path
        ? item.path === "/"
          ? this.activeRoute === item.path
          : this.activeRoute?.indexOf(item.path) !== -1
        : false;

      const customClasses = item.config?.isFlowPage ? "flow-page" : "";

      return `${customClasses} ${active ? "active" : ""}`;
    }

    renderGraphic(item) {
      if (item.id === "page_account" && this.profile)
        return html`<profile-picture
          size="36px"
          name="${app.session?.user?.firstname} ${app.session?.user?.lastname}"
          img="${app.session?.user?.profile_img_url}"
          uuid="${app.session?.user?.id}"
        >
        </profile-picture>`;

      if (item.config.text && !item.config.icon)
        return html`<span class="text">${item.config.text}</span>`;

      return html`<svg-icon icon="${item.config.icon}"></svg-icon>`;
    }
  }
);
