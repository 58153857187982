import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { html } from "lit";
import { GroupDomain } from "../../../domain/group-domain";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../../shared/pwa-page";
import { msg } from '@lit/localize';

export class PageNewGroup extends OnboardedMixin(AuthenticatedMixin(PWAPage)) {
  #domain;

  static get properties() {
    return {
      id: { type: String, routeOrigin: "pathname" },
      group: { type: Object },
    };
  }

  constructor() {
    super();
    this.#domain = new GroupDomain();
  }

  async connectedCallback() {
    await super.connectedCallback();
    this.createNew = this.id === 'new';

    if (!this.createNew) {
      await this.getDetails();
    }
  }

  async getDetails() {
    return Task.run(async () => {
      const res = await this.#domain.detail(this.id);
      this.group = res.data;

    }, { ghost: this })
  }

  async #createNewGroup(e) {
    if (e.detail?.name !== "--submit") return;

    let response;

    const task = async () => {
      const name = e.detail.value["group-name"];
      const type = e.detail.value["group-type"];
      const description = e.detail.value["group-description"] || "";

      if (this.createNew) {
        response = await this.#domain.create({name, type, description});
      } else {
        response = await this.#domain.edit(this.id, {name, type, description});
      }
    };

    await Task.run(task, {
      ghost: this,
      description: "Updating profile",
    });

    if (!response) return false;
    window.location.replace(`/network/groups/${response.slug ?? response.id}/view`);
  }

  render() {
    return html`
      <div class="center">
        <h1>${this.createNew ? msg('New') : msg('Edit')} ${msg("Group")}</h1>
      </div>
      <section class="card">
        <x-form @action=${this.#createNewGroup}>
          <form>
            <input
              name="group-name"
              data-label=${msg("Name")}
              required
              placeholder=${msg("Group Name")}
              value=${this.group?.name}
            />
            <label>
              <span data-label="">${msg("Type")}</span>
              <select name="group-type">
                <option value="open" ?selected=${this.group?.type === 'open'}>${msg("Open")}</option>
                <option value="closed" ?selected=${this.group?.type === 'closed'}>${msg("Closed")}</option>
              </select>
              <svg-icon icon="chevron" size="12px"></svg-icon>
            </label>
            <label>
              ${msg("Description")}
              <textarea
                name="group-description"
                rows="4"
                placeholder=${msg("Write a description here...")}
                .value="${this.group?.description ?? ''}"
              ></textarea>
            </label>
            <button type="submit" class="small wide" name="publish">${msg("Save")} ${msg("Group")}</button>
          </form>
        </x-form>
      </section>
    `;
  }
}
