import { askConfirm } from "@qogni-technologies/design-system/src/components/base/modal-dialog.js";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { LitElement, html, nothing } from "lit";
import { AccountDomain } from "../../domain/account-domain";
import { config } from "../../qogni-app-config";
import { nativeWebShare } from "../../shared/common";
import {msg, str} from '@lit/localize';

export class WorkoutCard extends LitElement {
  createRenderRoot() {
    return this;
  }

  #accountDomain;

  static get properties() {
    return {
      workout: { type: Object },

      // For saved items page
      saveId: { type: String },
      unsave: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.#accountDomain = new AccountDomain();
  }

  render() {
    const { id, name, thumbnail_urls, workout_category, duration } =
      this.workout;
    const imgUrl = thumbnail_urls[0] || "/assets/img/workout.webp";

    return html`
      <article>
        <a href="/workouts#${id}">
          <figure>
            <img src=${imgUrl} alt=${name} loading="lazy" />
          </figure>
          <div class="info">
            <badge-tag class="blue">${workout_category?.name}</badge-tag>
            <div class="name">${name}</div>
            <div class="meta">
              <svg-icon size="13.75px" icon="clock"></svg-icon>
              ${duration} ${msg("minutes")}
            </div>
          </div>
        </a>

        <div class="action-wrapper">
          <nav data-dropdown class="align-right img-button">
            <button title="Menu" class="simple">
              <svg-icon size="24px" icon="meatballs"></svg-icon>
            </button>
            <menu>
              ${!this.unsave
                ? html`
                    <li @click=${(e) => this.#onSave(e, id, "workout_id")}>
                      <svg-icon icon="save" size="20px"></svg-icon>
                      ${msg("Save")}
                    </li>
                  `
                : nothing}
              ${this.unsave
                ? html`
                    <li @click=${this.#onUnsave}>
                      <svg-icon icon="save" size="20px"></svg-icon>
                      ${msg("Unsave")}
                    </li>
                  `
                : nothing}
              <li @click=${this.#onShare}>
                <svg-icon
                  icon="${navigator.canShare ? "share" : "copy"}"
                  size="20px"
                ></svg-icon>
                ${navigator.canShare ? msg("Share") : msg("Copy Link")}
              </li>
            </menu>
          </nav>
        </div>
      </article>
    `;
  }

  async #onShare(e) {
    e.preventDefault();
    e.stopPropagation();

    const task = async () => {
      const { absoluteUrl } = config;
      const link = `${absoluteUrl}/workouts#${this.workout.id}`;

      await nativeWebShare({ title: this.workout.name, text: link });
      const parentElement = e.target.parentElement;
      parentElement.setAttribute("hidden", "");
    };

    Task.run(task, {
      ghost: this,
      global: false,
    });
  }

  async #onSave(e, id, key) {
    e.preventDefault();
    e.stopPropagation();

    const task = async () => {
      const parentElement = e.target.parentElement;
      parentElement.setAttribute("hidden", "");

      const res = await this.#accountDomain.saveItemToSavedList({ [key]: id });

      if (!res.status) return false;
      app.addToastMessage("Saved successfully.");
    };

    await Task.run(task, {
      ghost: this,
    });
  }

  async #onUnsave(e) {
    e.preventDefault();
    e.stopPropagation();

    const task = async () => {
      const parentElement = e.target.parentElement;
      parentElement.setAttribute("hidden", "");

      const userConfirmed = await askConfirm({
        title: msg("Confirm Unsave", { desc: "Title for unsave dialog." }),
        message:msg(str`Are you sure you want to unsave this ${"workout"}?`, { desc: "Description for Confirm Unsave dialog." }),
        okText: msg("Confirm"),
      });
      if (!userConfirmed) return;

      await this.#accountDomain.unsaveItemToSavedList(this.saveId);
      this.dispatchEvent(new CustomEvent("refresh"));
    };

    Task.run(task, {
      ghost: this,
    });
  }
}
customElements.define("workout-card", WorkoutCard);
