import { Broker } from "@pwf/pure-work/broker";
import { AccountDomain } from "../domain/account-domain";

const domains = {
  user: new AccountDomain(),
};

/**
 * Maps published broker messages to domain objects handling loading and saving data.
 */
export class DomainProxy {

  static open(){
    return new DomainProxy();
  }

  constructor() {
    Broker.instance
      .subscribe(`flow-step-save`, async (data) => {
        if (!data.isModified) return;

        const map = this.mapDomain(data);

        if (map && map.domain.setSingleProperty)
          return await map.domain.setSingleProperty(map.key, map.value);
        else console.warn("Could not save data for ", data);
      })
      .subscribe(`flow-step-load`, async (data) => {
        const map = this.mapDomain(data);
        if (map && map.domain.getSingleProperty)
          data.value = await map.domain.getSingleProperty(map.key);
        else console.warn("Could not load data for ", data);
      });
  }

  mapDomain(data) {
    const pair = data?.key?.split(".");
    if (pair && pair.length === 2) {
      if (domains[pair[0]]) {
        return {
          ...data,
          domain: domains[pair[0]],
          key: pair[1],
        };
      }
    }
  }
}
