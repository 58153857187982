import { html } from "lit";
import { ResultsBase } from "./results-base";
import { repeat } from "lit/directives/repeat.js";
import { msg } from '@lit/localize';

export class PagePerformance extends ResultsBase {
  constructor() {
    super();
    this.title = msg("Performance");
  }

  renderContent() {
    return html`
      ${this.renderVectorStat(this.coqnitiveScore, "cognitive-performance")}
      ${this.renderVectorStat(this.relaxRecoverScore, "relax-recover")}

      <section class="card">
        <h2>${msg("Your overall score", { desc: "Heading label displaying the user's total score for braincheck results." })}</h2>
        <p>
          ${msg("Based on the answers to the BrainCheck, these are your scores based on energy, sleep, focus, stress management and mental resilience.", { desc: "Descriptions text explaining the user's scores in key mental and physical areas based on BrainCheck responses." })}
        </p>

        ${repeat(this.fivePillars, (pillar, idx) => {
          const name = pillar.name
            .replace("_", " ")
            .toLowerCase()
            .split(" ")
            .map(function (word) {
              return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(" ");
          const colors = ["red", "yellow", "green", "blue"];
          const color = colors[idx % colors.length];

          return html`
            <section class="card">
              <h4 class="center">${name}</h4>
              <progress-indicator
                type="thick-line"
                theme="${color}"
                value="${pillar.version === 2 && pillar.combined_score
                  ? pillar.combined_score * 10
                  : pillar.normalized * 10}"
                max="10"
                label=""
                size=""
                bg=""
              >
              </progress-indicator>
            </section>
          `;
        })}
      </section>

      ${this.renderProgram()}
    `;
  }
}
