import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { html, nothing } from "lit";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { Converter } from "showdown";
import { QuestionnaireDomain } from "../../domain/questionnaire-domain";
import { getNeurotransmitterColor } from "../../shared/common";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../shared/pwa-page";
import {msg} from '@lit/localize';

export class ResultsBase extends OnboardedMixin(AuthenticatedMixin(PWAPage)) {
  domain;
  title;
  sampleGraph = [
    {
      color: "#e54271",
      label: msg("Dopamine"),
      value: 43,
      oldValue: 80,
    },
    {
      color: "#e7aa5e",
      label: msg("Acetylcholine"),
      value: 57,
      oldValue: 70,
    },
    {
      color: "#519bc5",
      label: "GABA",
      value: 47,
      oldValue: 50,
    },
    {
      color: "#49946a",
      label: msg("Serotonin"),
      value: 51,
      oldValue: 50,
    },
  ];

  constructor() {
    super();
    this.domain = new QuestionnaireDomain();
    this.loading = true;
  }

  static properties = {
    results: { type: Object, attribute: false },
    loading: { type: Boolean, attribute: false },
  };

  connectedCallback() {
    super.connectedCallback();
    Task.run(async () => {
      const response = await this.domain.latest();
      this.results = response.data;
      this.loading = false;
    });
    this.hasActiveProgram = app.session.user?.active_program !== null;
  }

  get availableProgram() {
    if (!this.results) return null;
    const bc = this.results?.braincheck;
    return bc?.available_program;
  }

  get coqnitiveScore() {
    if (!this.results) return null;

    const bc = this.results?.braincheck;
    const score = bc?.questionnaire_stats?.find(
      (n) => n.score_type === "cognitive-performance"
    );
    return score;
  }

  get relaxRecoverScore() {
    if (!this.results) return null;

    const bc = this.results?.braincheck;
    const score = bc?.questionnaire_stats?.find(
      (n) => n.score_type === "relax-recover"
    );
    return score;
  }

  get personalGraph() {
    if (!this.results) return [];

    let value = [];
    const bc = this.results?.braincheck;

    if (bc?.neurotransmitters_normalized?.a) {
      for (const key of Object.keys(bc.neurotransmitters_normalized.a)) {
        value.push({
          value: bc.neurotransmitters_normalized.a[key] * 100,
          color: getNeurotransmitterColor(key),
          label:
            key === "gaba"
              ? "GABA"
              : key.charAt(0).toUpperCase() + key.slice(1),
        });
      }

      value = this.sortNeurotransmitters(value);
    } else {
      value = this.sampleGraph;
    }
    return value;
  }

  get improvementGraph() {
    if (!this.results) return [];

    let value = [];
    const bc = this.results?.braincheck;

    if (bc?.neurotransmitters_normalized_combination) {
      for (const key of Object.keys(bc.neurotransmitters_normalized_combination)) {
        const {performance, potential} = bc.neurotransmitters_normalized_combination[key];
        value.push({
          value: potential*100,
          oldValue: performance*100,
          color: getNeurotransmitterColor(key),
          label: key === 'gaba' ? 'GABA': (key.charAt(0).toUpperCase() + key.slice(1))
        });

        value = this.sortNeurotransmitters(value);
      }
    } else {
      value = this.sampleGraph;
    }
    return value;
  }

  get fivePillars() {
    if (!this.results) return [];
    const pillars = [];
    const bc = this.results?.braincheck;
    if (
      this.results?.five_pillars_v2 &&
      Object.keys(this.results?.five_pillars_v2)?.length > 0
    ) {
      for (const pillarKey in this.results.five_pillars_v2) {
        pillars.push({
          name: pillarKey,
          version: 2,
          ...this.results.five_pillars_v2[pillarKey],
        });
      }
    } else {
      for (const pillarKey in bc?.five_pillars) {
        pillars.push({
          name: pillarKey,
          version: 1,
          ...bc?.five_pillars[pillarKey],
        });
      }
    }
    return pillars;
  }

  get personalProfile() {
    const bc = this.results?.braincheck;
    return bc?.result_profiles?.filter((p) => p.type === 'a')[0] ?? null;
  }

  get improvementProfile() {
    const bc = this.results?.braincheck;
    return bc?.result_profiles?.filter((p) => p.type === 'b')[0] ?? null;
  }

  get yourTopicsCount() {
    const hc = this.results?.healthcheck;
    if (!hc || !hc.totals) return 5;

    return hc.totals.filter(item => item.normalised > 1).length;
  }

  sortNeurotransmitters(array) {
    // Define the desired order
    const sortOrder = [msg("Dopamine"), msg("Acetylcholine"), "GABA", msg("Serotonin")];
    const orderByProperty = "label";

    return array.sort((a, b) => {
      const indexA = sortOrder.indexOf(a[orderByProperty]);
      const indexB = sortOrder.indexOf(b[orderByProperty]);

      // Handle undefined values (if a property value is not in the sortOrder array)
      if (indexA === -1 && indexB !== -1) return 1;
      if (indexA !== -1 && indexB === -1) return -1;
      if (indexA === -1 && indexB === -1) return 0;

      return indexA - indexB;
    });
  }

  renderVectorStat(score, type) {
    if (!score)
      return html`
        <section class="card preview">
          <hgroup>
            <h2>
              ${type === "cognitive-performance"
                ? html`${msg("Cognition and stress resilience")}`
                : html`${msg("Relax & Recover ability")}`}
            </h2>
          </hgroup>

          ${type === "cognitive-performance"
            ? html`
                <vector-stat
                  score="71"
                  label="${msg("Sufficient")}"
                  vector="brain"
                ></vector-stat>
              `
            : html`
                <vector-stat
                  score="79"
                  label="${msg("Sufficient")}"
                  vector="person"
                ></vector-stat>
              `}

          <p>
            ${type === "cognitive-performance"
              ? html`${msg("Steady as you go. Consider aiming for even greater cognitive heights.", { desc: "Encouragement to keep progressing and improve cognitive performance." })}`
              : html`${msg("You're at a crucial point for relaxation. Let's initiate recovery strategies.", { desc: "Prompt to start relaxation and recovery techniques at a key moment." })}`}
          </p>

          <details class="simple">
            <summary>
              <svg-icon icon="info"></svg-icon>
              ${msg("More information")}
            </summary>
            <!-- Content -->
            <section>
              <p>
                ${type === "cognitive-performance"
                  ? html`${msg("Though you're holding on, there's much untapped potential. Strengthening your relaxation foundation is essential. Regular self-care, coupled with consistent downtime, can do wonders.", { desc: "Encouragement to focus on relaxation and self-care to unlock potential and improve well-being." })}`
                  : html`${msg("Impressive! You excel in recovering from stress and tension. Your resilience ensures that you're always at your best, both mentally and physically. Continue to nurture this strength, and inspire others with your recuperative skills.", { desc: "Positive reinforcement for excelling in stress recovery and resilience, encouraging continued self-care and inspiration for others." })}`}
              </p>
            </section>
          </details>
        </section>
      `;

    const finalScore = score.output
      ? score.output * 100
      : this.domain.getScorePercentage(score.score);
    return html`
      <section class="card">
        <hgroup>
          <h2>
            ${type === "cognitive-performance"
              ? html`${msg("Cognition and stress resilience")}`
              : html`${msg("Relax & Recover ability")}`}
          </h2>
        </hgroup>

        <vector-stat
          score="${finalScore ?? 71}"
          label="${score.score}"
          vector="${type === "cognitive-performance" ? "brain" : "person"}"
        ></vector-stat>

        <translatable-content current-language="en">
          <p>${score.questionnaire_score_text}</p>
        </translatable-content>

        <details class="simple">
          <summary>
            <svg-icon icon="info"></svg-icon>
            ${msg("More information")}
          </summary>
          <!-- Content -->
          <section>
            <translatable-content current-language="en">
              <p>${score.questionnaire_score_description}</p>
            </translatable-content>
          </section>
        </details>
      </section>
    `;
  }

  renderProgram() {
    if (!this.availableProgram) return nothing;
    return html`
      <section class="card red">
        <h2>
          ${this.availableProgram?.product?.name ?? this.availableProgram?.name}
        </h2>

        <translatable-content
          current-language="en"
          style="--translate-button-color: white; --translate-icon-color: white;"
        >
          <p>
            ${this.availableProgram?.product?.description
              ? unsafeHTML(
                  new Converter().makeHtml(
                    this.availableProgram?.product?.description
                  )
                )
              : this.availableProgram.description}
          </p>
        </translatable-content>

        ${this.hasActiveProgram
          ? html`
              <p>
                <strong>
                  ${msg("This program is already activated. Head over to your program to see your progress and day-overview.", { desc: "Notification that the program is activated, prompting the user to check their progress and daily overview." })}
                </strong>
              </p>
              <a class="button wide white" href="/program">
                ${msg("Go to my program", { desc: "Button label to navigate to the user's program." })}
              </a>
            `
          : html`
              <a class="button wide white" href="/program/activation">
                ${msg("Activate my program today", { desc: "Button label to activate the user's program immediately." })}
              </a>
            `}
      </section>
    `;
  }

  renderPage() {
    if (this.loading) {
      return html`
        <app-shimmer class="title"></app-shimmer>
        <app-shimmer class="image mb"></app-shimmer>

        <app-shimmer class="title"></app-shimmer>
        <app-shimmer class="image"></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer class="mb"></app-shimmer>

        <app-shimmer class="title tiny"></app-shimmer>
        <app-shimmer class="image"></app-shimmer>
        <app-shimmer class="image"></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
      `;
    }

    return html` ${this.#renderHeader()} ${this.renderContent()} `;
  }


  #renderHeader() {
    if (this._title) {
      return html`
        <section class="hero center">
          <label @click=${app.goBack}>
            <svg-icon icon="arrow" size="16px" rotation="180"></svg-icon>
            ${msg("Back to results", { desc: "Button label to return to the previous results page." })}
          </label>
        </section>
      `;
    }

  }

  renderContent() {
    return nothing;
  }
}
customElements.define("results-base", ResultsBase);
