import { msg } from "@lit/localize";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { LitElement, html, nothing } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { TimelineDomain } from "../../domain/timeline-domain";
import "./comment-list-item";

export class CommentList extends LitElement {
  createRenderRoot() {
    return this;
  }

  #domain;
  #nextCursor = null;

  static get properties() {
    return {
      postId: { type: String },
      post: { type: Object },

      _comments: { type: Array },
      _pagination: { type: Object },
      _loading: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.#domain = new TimelineDomain();
    this._comments = [];
  }

  connectedCallback() {
    super.connectedCallback();
    this.#loadComments();
  }

  async refresh() {
    this.#nextCursor = null;
    await this.#loadComments();
  }

  async #loadComments() {
    const query = {
      ...(this.#nextCursor && { cursor: this.#nextCursor }),
    };

    const task = async () => {
      try {
        if (!this.postId) return;
        this._loading = true;
        const response = await this.#domain.getComments(this.postId, { query });

        if (this.#nextCursor) {
          this._comments = [...(this._comments ?? []), ...response.data];
        } else {
          this._comments = response.data;
        }
        this._pagination = response.pagination;
        this.#nextCursor = response.pagination.next_cursor;
        this._loading = false;
      } catch (err) {
        app.addToastMessage(`Timeline Comments: ${err}`, { type: "error" });
      }
      this.requestUpdate();
    };

    await Task.run(task);
  }

  render() {
    return html`
      ${repeat(this._comments, this.#renderComment.bind(this))}
      ${this._loading
        ? html`
              <app-shimmer class="title small"></app-shimmer>
              <app-shimmer class="mb tiny"></app-shimmer>

              <app-shimmer class="title small"></app-shimmer>
              <app-shimmer class="mb tiny"></app-shimmer>
          `
        : nothing}
      ${this._pagination && this._pagination.next_cursor
        ? html`
            <flex-container>
              <flex-item class="grow-1">
                <button
                  type="button"
                  class="tiny wide"
                  @click=${() => this.#loadComments()}
                >
                  ${msg("Load more comments...", {
                    desc: "Button label to load additional comments on post.",
                  })}
                </button>
              </flex-item>
            </flex-container>
          `
        : nothing}
    `;
  }

  #renderComment(comment) {
    return html`
      <comment-list-item
        .comment=${comment}
        .postId=${this.postId}
        .post=${this.post}
      ></comment-list-item>
    `;
  }
}

customElements.define("comment-list", CommentList);
