import { playSound } from "./common";

/**
 * Generic Speech Transcriber
 */
export class SpeechTranscriber extends EventTarget {
  #recognition;
  #callback;
  #isListening = false;
  #language = "en-US";

  constructor(language, callback) {
    super();
    this.#language = language;
    this.#callback = callback;
  }

  start() {
    const me = this;
    // eslint-disable-next-line no-undef
    this.#recognition = new webkitSpeechRecognition();

    try {
      me.#recognition.continuous = true;
      me.#recognition.lang = this.#language;

      me.#recognition.onstart = function () {
        
        me.#isListening = true;
        //me.resetIdleTimer()
        playSound("/assets/audio/record.mp3");
        me.dispatchEvent(new CustomEvent("start"));
      };

      me.#recognition.onspeechend = function(){
        this.stop()
      }

      me.#recognition.onresult = function (event) {
        //me.resetIdleTimer();
        var transcription = "";
        for (var i = event.resultIndex; i < event.results.length; ++i) {
          transcription += event.results[i][0].transcript;
        }
        me.#callback(transcription);
      };

      this.#recognition.onend = function () {
        me.#isListening = false;
      };

      this.#recognition.stop = function () {
        me.#isListening = false;
        me.dispatchEvent(new CustomEvent("stop"));
      };

      this.#recognition.start();
      this.#isListening = true;
    } catch (error) {
      console.error(
        "An error occurred while starting speech this.#recognition: ",
        error
      );
    }
    return this;
  }

  // resetIdleTimer(){
  //   const me = this;
  //   if(me.idleTimer)
  //     clearTimeout(me.idleTimer);

  //   me.idleTimer = setTimeout(() => {
  //     me.stop()
  //   }, 5000);
  // }

  stop() {
    if (this.#recognition) {
      this.#recognition.stop();
      this.#recognition.onresult = null;
      this.#recognition = null;
    }
    this.#isListening = false;
  }

  on(eventName, e) {
    this.addEventListener(eventName, e);
    return this;
  }

  get recording() {
    return this.#isListening;
  }
}
